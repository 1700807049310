@import '../../common';

.hero-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.0)
  ), url('#{$base-url}/resources/images/hero-bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}

.hero {
  position: relative;
  z-index: 1;
}
