// :root {
//   --var-one: something;
//   --var-two: something;
// }

$base-url: ''; // http://localhost:3000

// Colors
$foreground: theme('colors.foreground');
$foreground-alt: theme('colors.foreground-alt');
$background: theme('colors.background');
$background-alt: theme('colors.background-alt');
$primary-accent: theme('colors.primary-accent');
$lightBackground: theme('colors.light-background');
