// Variables
@import 'variables';
// ...

// Mixins
@import 'mixins';
// ...

// Bootstrap 5.3 class overrides and additions.
.btn-xs {
  padding: 0.15rem 0.5rem;
  min-height: calc(.5rem + calc(var(--bs-border-width)* 2));
}

.text-bold {
  font-weight: bold;
}

.alert-sm {
  padding: 5px 10px;
  font-size: 0.8rem;
}

.table {
  &.table-sm {
    font-size: 14px;
  }
}

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}
