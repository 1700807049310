@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); // font-family: "Montserrat", sans-serif;
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); // font-family: "Roboto", sans-serif;
// @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap'); // font-family: "Fira Code", monospace;

@import './common';

:root {
  // --foreground: black;
  // --background: white;
}

@media (prefers-color-scheme: dark) {
  :root {
    // --foreground: white;
    // --background: black;
  }
}

// @layer utilities {
//   .text-balance {
//     text-wrap: balance;
//   }
// }

html,
body {
  overscroll-behavior-y: none;
}

html {
  scroll-behavior: smooth;

  &:not(.dark) {
    body {
      background-color: $lightBackground;

      .portfolio {
        .fade-out-right {
          mask-image: linear-gradient(to right, $lightBackground 70%, rgb(from $lightBackground r g b / 0%) 100%);
        }
      }
    }
  }

  body {
    background-color: $background;
    margin: 0;
    font-family:
      var(--font-roboto),
      -apple-system,
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    code {
      font-family: var(--font-fira-code), monospace;
    }

    main {
    }
  }
}

.portfolio {
  svg.svg-inline--fa:not(.regular-icon) {
    margin-right: 5px;
  }

  .details[title] {
    // font-style: italic;
    text-decoration: dotted underline;
    cursor: help;
  }

  .fade-out-right {
    mask-image: linear-gradient(to right, $background 70%, rgb(from $background r g b / 0%) 100%);
  }

  .animated-arrow {
    -webkit-animation: fadeInDown 1.5s infinite;
    animation: fadeInDown 1.5s infinite;

    @keyframes fadeInDown {
      0% {
        opacity: 0;
        top: -25px;
      }
      100% {
        opacity: 1;
        top: 10px;
      }
    }
  }

  .heading-border {
    position: relative;
    display: inline-block;

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 70%;
      height: 2px;
      background-color: $primary-accent;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s;
    }
  }

  .font-outlined {
    -webkit-text-stroke: 2px #fff;
    color: rgba(0, 0, 0, 0);
  }

  .reverse {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
}

// Mobile first, min-width based.
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .portfolio {
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .portfolio {
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .portfolio {
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .portfolio {
  }
}

// Reversed, max-width based.
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
  .portfolio {
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
  .portfolio {
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 992px) {
  .portfolio {
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {
  .portfolio {
  }
}

// Extra large devices (large desktops)
@media (min-width: 1200px) {
  .portfolio {
  }
}
